/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/browser';

declare const window: any;

// Abstraction for analytics tracking across the app.

const GOOGLE_TAG_ID = 'AW-865171774';
const GOOGLE_EVENT_TO_ID_MAPPING: { [event: string]: string } = {
  'Paid for Course': 'jT9_CO34hLQBEL7yxZwD',
  'Lessons Purchased': 'mOTbCOCN_HoQvvLFnAM',
};

const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

export const page = (label: string) => {
  if (IS_PRODUCTION) {
    window.analytics.page(label);
    if (window.ndp) {
      // Nextdoor
      window.ndp('track', 'PAGE_VIEW');
    }
  } else {
    console.log('page:', label);
  }
};

export const segmentIdentify = (uid: string, payload: any) => {
  /**
   * This is separate from `identify` below because we only want to call
   * this one in some cases, not on every change to the `users` collection,
   * or `profile` in Redux. The Firebase Trigger handles updating the user
   * in Segment.
   */
  if (IS_PRODUCTION) {
    window.analytics.identify(uid, payload);
  } else {
    console.log('identify:', uid, payload);
  }
};

export const identify = (uid: string, payload: any = null) => {
  Sentry.configureScope((scope: any) => {
    scope.setUser({
      email: payload.email,
      id: uid,
      isAdmin: payload.isAdmin ?? false,
      isInstructor: payload.isInstructor ?? false,
    });
    scope.setTags({
      isAdmin: payload.isAdmin ?? false,
      isInstructor: payload.isInstructor ?? false,
    });
  });

  if (IS_PRODUCTION) {
    if (window.clarity && uid) {
      window.clarity('identify', uid);
    }

    if (window.gtag && payload.email) {
      // When we have multiple we can pass an array.
      const email = payload.billingEmail ? [payload.email, payload.billingEmail] : payload.email;
      window.gtag('set', 'user_data', { email });
    }
  }
};

export const track = (label: string, payload: any = null) => {
  if (!IS_PRODUCTION) {
    console.log('track:', label, payload);
    return;
  }

  window.analytics.track(label, payload);

  if (window.clarity) {
    window.clarity('set', 'event', label);
  }

  if (window.gtag && GOOGLE_EVENT_TO_ID_MAPPING[label]) {
    window.gtag('event', 'conversion', {
      send_to: `${GOOGLE_TAG_ID}/${GOOGLE_EVENT_TO_ID_MAPPING[label]}`,
      value: payload.value,
      currency: 'USD',
      transaction_id: payload.orderId,
    });
  }

  if (label === 'Order Completed' && payload.value) {
    // Bing
    window.uetq = window.uetq || [];
    window.uetq.push(
      'event',
      'track',
      {
        event_label: 'Order Completed',
        revenue_value: payload.value,
        currency: 'USD',
      },
    );

    // Nextdoor
    if (window.ndp) {
      window.ndp('track', 'CONVERSION');
    }
  }
};
