/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import { track } from './analytics';

type Extras = Record<string, any>;

type ErrorWithMessage = {
  message: string
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object'
    && error !== null
    && 'message' in error
    && typeof (error as Record<string, unknown>).message === 'string'
  );
}

export const isErrorStatus = (status: number) => status >= 400;

export const logErrorMessage = (
  message: string,
  extras?: Extras,
) => {
  Sentry.captureMessage(message);
  track('appError', { ...extras, message });
  if (extras) {
    Sentry.setExtras({ extras: JSON.stringify(extras, null, 2) });
  }
  if (process.env.REACT_APP_ENV !== 'production') {
    console.error(message);
  }
};

export const logError = (
  error: unknown,
  extras?: Extras,
  isErrorBoundary?: boolean,
) => {
  if (typeof error === 'string') {
    logErrorMessage(error, extras);
    return;
  }

  if (isErrorWithMessage(error)) {
    Sentry.withScope((scope) => {
      scope.setExtras({ extras: JSON.stringify(extras, null, 2) });
      if (isErrorBoundary) {
        scope.setTag('isErrorBoundary', true);
      }
      Sentry.captureException(error);
    });
    track('appError', { ...extras, message: error.message, isErrorBoundary });
  }

  if (process.env.REACT_APP_ENV !== 'production') {
    console.error(error, extras);
  }
};
